import { RECALL_CLASSIFICATIONS, RECALL_STATUS,PROVIDER_EVENTLISTING_STATUS,PRODUCT_DISRUPTION_TYPE, OUTPUT_DATE_FORMAT,COMMUNICATION_TYPE, ANTICIPATED_DURATION } from '../../../misc/commons'
import DocumentViewModel from '../../../components/part/shared/DocumentViewModel'
import ActionType from '../../../components/part/provider/ActionType'
import communicationNoticeService from '../../../services/provider/communicationNotices'
import _ from 'lodash'
import { String } from 'core-js'
export default {
    props: {
        organizationId: { type: Number, default: null },
        communicationId: { type: String, default: null },
        providerCommunicationId: { type: Number, default: null },
        communicationType: { type: String, default: COMMUNICATION_TYPE.RECALL },
    },
    components: {
        DocumentViewModel,
        ActionType,
    },
    data() {
        return {
            recall_classifications: RECALL_CLASSIFICATIONS,
            recall_status: RECALL_STATUS,
            anticipated_duration: ANTICIPATED_DURATION,
            product_disruption_status: PROVIDER_EVENTLISTING_STATUS,
            disruption_type: PRODUCT_DISRUPTION_TYPE,
            recall: COMMUNICATION_TYPE.RECALL,
            disruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            item: null,
            isOlderExpanded: false,
            internalActions: null,
            cnt: 1,
            isExpanded: true,
            editMode: true,
            responder: {
                Id: null,
                providerCommunicationId: null,
                communicationAffectedCustomerId: null,
                firstName: null,
                lastName: null,
                status: null,
                email: null
            },
            labels: {
                webformHeader: 'Recall Information',
                riskToHealth: 'Risk To Health',
                name: 'Recall Information',
                communicationUID: 'Recall ID',
                type: 'Recall Type',
                status: 'Recall Status',
                communicationDate: 'Recall Date',
                categories: 'Recall Category',
                supportingMaterials: 'Supporting Materials',
                products: 'Recall Products',
            },
            responderInfo: {
                title: this.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION ? 'Title' : 'Title *',
                phoneNumber: this.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION ? 'Phone Number' : 'Phone Number *',
                email: this.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION ? 'Email' : 'Email *'
            },
            responderInfo_rules: {
                title: this.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION ? '' : 'required',
                phoneNumber: this.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION ? '' : 'required|phoneNumber',
                email: this.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION ? '' : 'required|email'
            },
            histories: [],
            moreLess: 'See Less Information',
            headerName: null
        }
    },
    created() {
        this.labels={
            webformHeader: this.communicationType== this.recall? 'Recall Information':'Advisory Notice :',
            riskToHealth: this.communicationType== this.recall? 'Risk To Health':'Alternatives & Conservation Measures',
            name: this.communicationType== this.recall? 'Recall Information':'Advisory Notice :',
            communicationUID: this.communicationType== this.recall? 'Recall ID':'Supply Advisory ID',
            type: this.communicationType== this.recall? 'Recall Type':'Type',
            status: this.communicationType== this.recall? 'Recall Status':'Status',
            communicationDate: this.communicationType== this.recall? 'Recall Date':'Advisory Date',
            categories: this.communicationType== this.recall? 'Recall Category':'Category',
            supportingMaterials: this.communicationType== this.recall? 'Supporting Materials':'Attachments',
            products: this.communicationType== this.recall? 'Recall Products':'Affected Products',
        },
        this.communicationNotice()
    },
    methods: {
        fetchCommunication() {
            if(this.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION)
            {
                communicationNoticeService.communicationForPd(this.organizationId, this.communicationId, this.providerCommunicationId, this.responder.communicationAffectedCustomerId).then(resp => {
                    if (!resp.error) {
                        this.item = resp.data.d
                        this.item.updates.forEach(s => {
                            s.disruptionUpdate = s.disruptionUpdate.replaceAll('ql-align-right', 'text-right').replaceAll('ql-align-center', 'text-center')
                                .replaceAll('ql-indent-1','indent-1').replaceAll('ql-indent-2','indent-2').replaceAll('ql-indent-3','indent-3')
                                .replaceAll('ql-indent-4','indent-4').replaceAll('ql-indent-5','indent-5').replaceAll('ql-indent-6','indent-6')
                                .replaceAll('ql-indent-7','indent-7').replaceAll('ql-indent-8','indent-8')
                        })
                        this.headerName = resp.data.d.communicationName + ' - ' + resp.data.d?.recallsitename
                        if (this.item.acknowledge_at === null || this.item.acknowledge_at === undefined) {
                            this.editMode = !(this.item.acknowledge_at === null || this.item.acknowledge_at === undefined)
                            this._showOk(' Please acknowledge that you have received this communication.', {title: this.communicationType==this.recall ? 'Acknowledge Recall' : 'Acknowledge Advisory', variant: 'info'}).then(ok => {
                                if (ok) {
                                    this.acknowledge()
                                }
                            })
                        }
                        this.internalActions = resp.data.d.actions
                        for (let i = 0; i < this.internalActions.length; i++) {
                            let List = []
                            if (this.internalActions[i].action_type == 'MultipleChoice') {
                                for (let j = 0; j < this.internalActions[i].actionValues.length; j++) {
                                    List.push({ value: this.cnt, text: this.internalActions[i].actionValues[j].value, })
                                    if (!this.internalActions[i].isAllowOnlyOne) {
                                        this.internalActions[i].response_data.valueMultiChoice.forEach((element) => {
                                            if (element.valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                                this.internalActions[i].multiSelectedValues.push(this.cnt)
                                            }
                                        })
                                    } else {
                                        if (this.internalActions[i].response_data.valueMultiChoice.length > 0) {
                                            if (this.internalActions[i].response_data.valueMultiChoice[0].valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                                this.internalActions[i].singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                    this.cnt++
                                }
                                this.internalActions[i].actionValues = []
                                this.internalActions[i].actionValues = List
                            }
                        }
                    }
                })
            }
            else{
                communicationNoticeService.communication(this.organizationId, this.communicationId, this.providerCommunicationId).then(resp => {
                    if (!resp.error) {
                        this.item = resp.data.d
                        this.item.updates.forEach(s => {
                            s.disruptionUpdate = s.disruptionUpdate.replaceAll('ql-align-right', 'text-right').replaceAll('ql-align-center', 'text-center')
                                .replaceAll('ql-indent-1','indent-1').replaceAll('ql-indent-2','indent-2').replaceAll('ql-indent-3','indent-3')
                                .replaceAll('ql-indent-4','indent-4').replaceAll('ql-indent-5','indent-5').replaceAll('ql-indent-6','indent-6')
                                .replaceAll('ql-indent-7','indent-7').replaceAll('ql-indent-8','indent-8')
                        })
                        this.headerName = resp.data.d.communicationName + ' - ' + resp.data.d?.recallsitename
                        if (this.item.acknowledge_at === null || this.item.acknowledge_at === undefined) {
                            this.editMode = !(this.item.acknowledge_at === null || this.item.acknowledge_at === undefined)
                            this._showOk(' Please acknowledge that you have received this communication.', {title: this.communicationType==this.recall ? 'Acknowledge Recall' : 'Acknowledge Advisory', variant: 'info'}).then(ok => {
                                if (ok) {
                                    this.acknowledge()
                                }
                            })
                        }
                        this.internalActions = resp.data.d.actions
                        for (let i = 0; i < this.internalActions.length; i++) {
                            let List = []
                            if (this.internalActions[i].action_type == 'MultipleChoice') {
                                for (let j = 0; j < this.internalActions[i].actionValues.length; j++) {
                                    List.push({ value: this.cnt, text: this.internalActions[i].actionValues[j].value, })
                                    if (!this.internalActions[i].isAllowOnlyOne) {
                                        this.internalActions[i].response_data.valueMultiChoice.forEach((element) => {
                                            if (element.valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                                this.internalActions[i].multiSelectedValues.push(this.cnt)
                                            }
                                        })
                                    } else {
                                        if (this.internalActions[i].response_data.valueMultiChoice.length > 0) {
                                            if (this.internalActions[i].response_data.valueMultiChoice[0].valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                                this.internalActions[i].singleSelectedValues = this.cnt
                                            }
                                        }
                                    }
                                    this.cnt++
                                }
                                this.internalActions[i].actionValues = []
                                this.internalActions[i].actionValues = List
                            }
                        }
                    }
                })
            }
        },
        communicationNotice() {
            communicationNoticeService.communicationNotice(this.providerCommunicationId, this.communicationType, this.$route.query.e).then(resp => {
                if (!resp.error) {
                    this.responder = resp.data.d
                    this.responder.email = this.$route.query.e
                    if (this.responder.status !== 'RESPONDED') {
                        this.fetchCommunication()
                        this.communicationHistories()
                    } else {
                        if (this.communicationType == this.recall) {
                            this.$router.push({
                                name: 'confirmation', params: { organizationId: this.organizationId, communicationId: this.communicationId, providerCommunicationId: this.providerCommunicationId, communicationType: this.communicationType }
                            })
                        } else {
                            this.$router.push({
                                name: 'disruptionConfirmation', params: { organizationId: this.organizationId, communicationId: this.communicationId, providerCommunicationId: this.providerCommunicationId, communicationAffectedCustomerId: this.responder.communicationAffectedCustomerId, email: this.$route.query.e, communicationType: this.communicationType }
                            })
                        }
                    }
                }
            })
        },
        updateCommunicationNotice() {
            communicationNoticeService.updateCommunicationNotice(this.responder).then(resp => {
                if (!resp.error) {
                    this._showToast('Successfully Updated!', { variant: 'success' })
                    if (this.responder.status === 'RESPONDED') {
                        setTimeout(() => {
                            if (this.communicationType == this.recall) {
                                this.$router.push({
                                    name: 'confirmation', params: { organizationId: this.organizationId, communicationId: this.communicationId, providerCommunicationId: this.providerCommunicationId, communicationType: this.communicationType }
                                })
                            } else {
                                this.$router.push({
                                    name: 'disruptionConfirmation', params: { organizationId: this.organizationId, communicationId: this.communicationId, providerCommunicationId: this.providerCommunicationId, email: this.responder.email, communicationAffectedCustomerId: this.responder.communicationAffectedCustomerId, communicationType: this.communicationType }
                                })
                            }
                        }, 1000)
                    } else {
                        this.communicationNotice()
                    }
                }
            })
        },
        communicationHistories() {
            communicationNoticeService.communicationHistories(this.organizationId, this.communicationId, this.providerCommunicationId).then(resp => {
                if (!resp.error) {
                    this.histories = resp.data.d
                }
            })
        },
        updateCommunicationActions() {
            var act = this.internalActions
            for (let i = 0; i < this.internalActions.length; i++) {
                if (act[i].action_type == 'MultipleChoice') {
                    if (act[i].multiSelectedValues == null && (act[i].singleSelectedValues == null || act[i].singleSelectedValues == 0)) {
                        this.internalActions[i].singleSelectedValues = 0
                    }
                    else if (act[i].multiSelectedValues.length == 0 && (act[i].singleSelectedValues == null || act[i].singleSelectedValues == 0)) {
                        this.internalActions[i].singleSelectedValues = 0
                    }
                }
            }
            communicationNoticeService.updateCommunicationActions(this.organizationId, this.communicationId, this.communicationType, this.internalActions, this.providerCommunicationId, this.responder.communicationAffectedCustomerId)
                .then((resp) => {
                    if (!resp.error) {
                        this.updateCommunicationNotice()
                    }
                })
        },
        addclick() {
            this.$refs.modal.show()
        },
        expandMoreLess() {
            if (this.isExpanded == false) this.moreLess = 'See Less Information'
            else this.moreLess = 'See More Information'
        },
        async save() {
            if (await this.$refs['observer'].validate()) {
                this.responder.status = 'RESPONDED'
                this.updateCommunicationActions()
            }
        },
        saveAsDraft() {
            this.responder.status = 'DRAFT'
            this.updateCommunicationActions()
        },
        ChangeIdentifires(fields, field, event) {
            let rownumber = field.options.filter(x => x.value == event)[0].rowNumber
            let uniquefields = fields.filter(x => x.is_unique_identifier == true && x.field_name != field.field_name)
            for (let index = 0; index < uniquefields.length; index++) {
                let uniquefield = fields.filter(x => x.is_unique_identifier == true && x.field_name == uniquefields[index].field_name)[0]
                uniquefield.selected_value = uniquefield.options.filter(x => x.rowNumber == rownumber)[0].value
            }
        },
        acknowledge() {
            communicationNoticeService.acknowledgeCommunication(this.organizationId, this.communicationId, this.providerCommunicationId, this.responder.email).then(resp => {
                if (!resp.error) {
                    this.fetchCommunication()
                    this.editMode = true
                    this._showNotify('Acknowledge', this.communicationType==this.recall? 'The recall has been acknowledged':'The supply advisory has been acknowledged', null, { animationIcon: 'success', hideFooter: true, hideHeader: true })
                }
            })
        },
        acceptNumber() {
            this.responder.phoneNumber =  (this.responder.phoneNumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[1] ? this.responder.phoneNumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[1] + '-' : '') + this.responder.phoneNumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[2] + (this.responder.phoneNumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[3] ? '-' + this.responder.phoneNumber?.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)[3] : '')
        },
    },
}
